<template>
    <div class="movement-resume-container">
        <div class="header">
            <div class="title">Saldo das contas</div>
            <div>
                <Loading :class="{ refresh: true, reload: true, loading: loading }" @click="refreshResume"/>
                <multiselect
                    track-by="value"
                    label="label"
                    v-model="bankAccount"
                    placeholder="Todas as contas"
                    :options="bankAccounts"
                    :allow-empty="false"
                    :showLabels="false"
                    :showNoResults="false"
                    :disabled="false"
                    @select="getMovementsCount"
                >
                    <template slot="caret">
                        <div class="chevron">
                            <ChevronDown class="icon" />
                        </div>
                    </template>
                    <template slot="singleLabel" slot-scope="{ option }" >
                        <span class="account-options">{{ option.label }}</span>
                    </template>
                    <template slot="noOptions">
                        Nenhuma opção
                    </template>
                    <template slot="noResult">
                        Nenhum resultado
                    </template>
                </multiselect>
            </div>
        </div>
        <hr/>
        <div class="content">
            <div>
                <div class="label">Valor total</div>
                <div class="value blue">{{ parseNumberToMoney(getTotalBalance()) }}</div>
            </div>
            <div>
                <div class="label">Movimentações</div>
                <div class="value green">{{ movements }}</div>
            </div>
            <div v-for="(bankAccount, index) in getFilteredAccounts()" :key="index">
                <div class="label">{{ bankAccount.name }}</div>
                <div class="value" :class="{ positive: bankAccount.account_balance >= 0, negative: bankAccount.account_balance < 0 }">{{ parseNumberToMoney(bankAccount.account_balance) }}</div>
            </div>
        </div>

    </div>
</template>
<script>
import api from '../api'
import { parseNumberToMoney } from '@/utils/moneyHelper'

export default {
    components: {
        ChevronDown: () => import('@/assets/icons/chevron-down.svg'),
        Loading: () => import('@/assets/icons/loading.svg'),
    },
    props: {
        bankAccounts: Array,
    },
    data() {
        return {
            bankAccount: null,
            movements: 0,
            loading: false,
        }
    },
    methods: {
        parseNumberToMoney,
        getFilteredAccounts() {
            return this.bankAccounts
                .filter(el => el.id)
                .filter(el => this.bankAccount?.id ? el.id === this.bankAccount.id : true)
        },
        getTotalBalance() {
            return this.getFilteredAccounts().reduce((acc, el) => acc + el.account_balance, 0)
        },
        getMovementsCount() {
            const bankAccountIds = this.getFilteredAccounts().map(el => el.id)
            api.getMovementsCount(bankAccountIds)
                .then(({ data }) => {
                    this.movements = data.movements
                })
        },
        refreshResume() {
            if(!this.loading) {
                this.loading = true 
                this.$emit('refresh')
            }  
        }
    },
    watch: {
        bankAccounts(el) {
           this.loading = false
           el?.length && this.getMovementsCount()
        }
    }
}
</script>
<style lang="scss" scoped>
.movement-resume-container {
    padding: 1.5rem;
    border: 1px solid var(--neutral-200);
    border-radius: 8px;
    box-shadow: 0px 2px 1px rgba(12, 29, 89, 0.04);
    margin-bottom: 2rem;
    .icon {
        stroke: var(--blue-500) !important;
    }
    .header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        .title {
            font-weight: 500;
            font-size: 1rem;
            margin-bottom: 0;
            color: var(--dark-blue);
        }
        div {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 1rem;
        }
        .refresh {
            width: 48px;
        }
    }
    .content {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        gap: 1.5rem;
        overflow-y: auto;
        .label {
            font-weight: 600;
            font-size: 0.8rem;
            color: var(--dark-blue);
            white-space: nowrap;
        }
        .value {
            font-weight: 700;
            font-size: 1.5rem;
            white-space: nowrap;
        }

        ::-webkit-scrollbar {
            width: 12px;
            height: 12px;
        }

        ::-webkit-scrollbar-track {
            background: var(--neutral-000);
            border-radius: 0 0 8px 0;
        }

        ::-webkit-scrollbar-thumb {
            background-color: var(--neutral-300);
            border-radius: 100px;
            border: 3px solid var(--neutral-000);
        }

        ::-webkit-scrollbar-thumb:hover {
            cursor: pointer;
            background: var(--neutral-200);
        }
    }
    .blue {
        color: var(--blue-500);
    }
    .green {
        color: var(--states-success-dark-green);
    }
    .positive {
        color: var(--dark-blue);
    }
    .negative {
        color: var(--states-error);
    }
}

</style>